$hyoo_life $mol_page
	title @ \the Life Game
	plugins /
		<= Theme $mol_theme_auto
	speed? 0
	head /
		<= Title
		<= Step $mol_button_minor
			sub / <= Step_icon $mol_icon_step_forward
			click <= step
		<= Time $mol_switch
			value? <=> speed_str? \
			options *
				5 <= Normal $mol_icon_play
				1000 <= Fast $mol_icon_fast_forward
		<= Population $mol_view sub / <= population
		<= Tools
	tools /
		<= Lights $mol_lights_toggle
		<= Source_link $mol_link_source
			uri \https://github.com/hyoo-ru/life.hyoo.ru
		<= Reset $mol_link
			hint @ \Kill All
			arg * snapshot \
			sub /
				<= Reset_icon $mol_icon_eraser
		<= Store_link $mol_link_lazy
			uri_generated <= store_link?val \
			hint <= store_link_hint @ \Store snapshot
			sub /
				<= Stored $mol_icon_stored
	-
	sub /
		<= Head
		<= Map $hyoo_life_map
			speed <= speed
			snapshot <= snapshot \3~pab~paa~1ekj~0~hr8lxc~hr7wn5~hr8lxe
			snapshot_current => snapshot_current
			population => population
			step => step
